import { useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { ActivityLog } from "../gql/graphql";
import { ActivityLogFeedItem } from "./ActivityLogFeedItem";

const GET_PROFILE_ACTIVITY_FEED = gql`
  query GetProfileActivityFeed($username: String!) {
    getActivityLogFeed(request: { username: $username }) {
      activityLogID
      templatedLog
      createdAt
    }
  }
`;

const GET_MOST_RECENTLY_EDITED_DRAFT = gql`
  query GetMostRecentlyEditedDraft {
    getMostRecentlyEditedDraft {
      draftID
    }
  }
`;

function ProfileActivityFeed() {
  const { username } = useParams();
  const { data, loading } = useQuery(GET_PROFILE_ACTIVITY_FEED, {
    variables: { username },
    fetchPolicy: "no-cache",
  });
  const [showWordLog, setShowWordLog] = useState(false);
  const [showTimeTooltip, setShowTimeTooltip] = useState(false);

  const { data: mostRecentlyEditedDraftData } = useQuery(
    GET_MOST_RECENTLY_EDITED_DRAFT,
    {
      fetchPolicy: "no-cache",
    }
  );

  return (
    <div
      className="flex flex-col relative"
      style={{
        maxHeight: "calc(100dvh - 60px)",
        height: "calc(100dvh - 60px)",
        minHeight: 500,
        overflow: "auto",
        width: "100%",
        alignItems: "flex-start",
      }}
    >
      {data?.getActivityLogFeed?.map((activityLog: ActivityLog) => (
        <ActivityLogFeedItem
          key={activityLog.activityLogID}
          activityLog={activityLog}
          draftID={
            mostRecentlyEditedDraftData?.getMostRecentlyEditedDraft?.draftID
          }
        />
      ))}
    </div>
  );
}

export default ProfileActivityFeed;
