import { AddSquare, Lock, Timer1, User } from "iconsax-react";
import EarthBackground from "../assets/earth-background.png";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RoomMetadata, UserRoomStatus } from "../gql/graphql";
import { gql, useMutation, useQuery } from "@apollo/client";
import { formatTimeDifference } from "../utils/string";
import { Avatar, Button } from "@nextui-org/react";
import { motion } from "framer-motion";

const GET_ROOM_LIST = gql`
  query GetRoomList {
    getRoomList {
      sharedRooms {
        roomID
        name
        timeLeftSeconds
        endTime
        imageDownloadURL
        private
        permanent
        ownerID
        numUsersInRoom
        friendsInRoom {
          username
          userID
          profilePhotoURL
        }
        roomStatus
      }
      ownedRooms {
        roomID
        name
        timeLeftSeconds
        endTime
        imageDownloadURL
        private
        permanent
        ownerID
        numUsersInRoom
        friendsInRoom {
          username
          userID
          profilePhotoURL
        }
        roomStatus
      }
    }
  }
`;

const GET_MOST_RECENTLY_EDITED_DRAFT = gql`
  query GetMostRecentlyEditedDraft {
    getMostRecentlyEditedDraft {
      draftID
    }
  }
`;

const DECLINE_ROOM_INVITE = gql`
  mutation DeclineRoomInvite($roomID: String!) {
    declineRoomInvite(request: { roomID: $roomID }) {
      success
    }
  }
`;

function roomsAreSame(room1: RoomMetadata, room2: RoomMetadata) {
  return (
    room1.roomID === room2.roomID &&
    room1.name === room2.name &&
    room1.private === room2.private &&
    room1.permanent === room2.permanent &&
    room1.ownerID === room2.ownerID &&
    room1.numUsersInRoom === room2.numUsersInRoom &&
    JSON.stringify(room1.friendsInRoom) ===
      JSON.stringify(room2.friendsInRoom) &&
    room1.imageDownloadURL?.split("?")[0] ===
      room2.imageDownloadURL?.split("?")[0]
  );
}

const CachedBackgroundImageDiv = React.memo(
  ({
    roomID,
    draftID,
    imageURL,
    children,
  }: {
    imageURL: string;
    roomID: string;
    draftID?: string;
    children: React.ReactNode;
  }) => {
    const navigate = useNavigate();
    return (
      <div
        className="flex flex-col items-center justify-end cursor-pointer"
        style={{
          backgroundImage: `url(${imageURL})`,
          backgroundSize: "cover",
          width: 200,
          height: 200,
          minWidth: 200,
          minHeight: 200,
          borderRadius: 10,
        }}
        onClick={() => {
          navigate(`/room/${roomID}${draftID ? `/draft/${draftID}` : ""}`);
        }}
      >
        {children}
      </div>
    );
  }
);

function getUpdatedRoomList(
  newRooms: RoomMetadata[],
  oldRooms: RoomMetadata[]
) {
  let sharedRoomsChanged = false;

  let finalRooms = [];

  for (let i = 0; i < newRooms.length; i++) {
    const matchingRoom = oldRooms.find(
      (room) => room.roomID === newRooms[i].roomID
    );
    if (!matchingRoom) {
      sharedRoomsChanged = true;
    } else if (!roomsAreSame(newRooms[i], matchingRoom)) {
      sharedRoomsChanged = true;
    }
    const urlsAreEqual =
      newRooms[i].imageDownloadURL?.split("?")[0] ===
      matchingRoom?.imageDownloadURL?.split("?")[0];
    finalRooms.push({
      ...newRooms[i],
      imageDownloadURL: urlsAreEqual
        ? matchingRoom?.imageDownloadURL
        : newRooms[i].imageDownloadURL,
    });
  }

  return { finalRooms, sharedRoomsChanged };
}
export default function RoomsGrid() {
  const [createNewHovered, setCreateNewHovered] = useState(false);

  const { data: mostRecentlyEditedDraftData } = useQuery(
    GET_MOST_RECENTLY_EDITED_DRAFT,
    {
      fetchPolicy: "no-cache",
    }
  );

  const [declineRoomInvite] = useMutation(DECLINE_ROOM_INVITE);
  const navigate = useNavigate();

  const [currentTime, setCurrentTime] = useState(new Date());

  const [hoveredRoomID, setHoveredRoomID] = useState<string | null>(null);
  const [hoveredFriendID, setHoveredFriendID] = useState<string | null>(null);

  const [invitedRooms, setInvitedRooms] = useState<RoomMetadata[]>([]);
  const [sharedRooms, setSharedRooms] = useState<RoomMetadata[]>([]);
  const [ownedRooms, setOwnedRooms] = useState<RoomMetadata[]>([]);

  const lastRefetchTime = useRef(0);

  const updateRoomsFromRefetch = useCallback(
    (data: any) => {
      if (data?.getRoomList) {
        const { sharedRooms: sharedRoomsData, ownedRooms: ownedRoomsData } =
          data.getRoomList;

        // Filter out invited rooms from shared rooms
        const filteredSharedRooms = sharedRoomsData.filter(
          (room: RoomMetadata) => room.roomStatus !== UserRoomStatus.Invited
        );

        const { finalRooms, sharedRoomsChanged } = getUpdatedRoomList(
          filteredSharedRooms,
          sharedRooms
        );
        if (sharedRoomsChanged) {
          setSharedRooms(finalRooms);
        }

        const {
          finalRooms: finalOwnedRooms,
          sharedRoomsChanged: ownedRoomsChanged,
        } = getUpdatedRoomList(ownedRoomsData, ownedRooms);
        if (ownedRoomsChanged) {
          setOwnedRooms(finalOwnedRooms);
        }
        const invitedRoomsData = sharedRoomsData.filter(
          (room: RoomMetadata) => room.roomStatus === UserRoomStatus.Invited
        );
        const {
          finalRooms: finalInvitedRooms,
          sharedRoomsChanged: invitedRoomsChanged,
        } = getUpdatedRoomList(invitedRoomsData, invitedRooms);
        if (invitedRoomsChanged) {
          setInvitedRooms(finalInvitedRooms);
        }
      }
    },
    [invitedRooms, ownedRooms, sharedRooms]
  );

  const { data, loading, refetch } = useQuery(GET_ROOM_LIST, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      updateRoomsFromRefetch(data);
    },
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      // only refetch every 5 seconds
      if (Date.now() - lastRefetchTime.current > 5000) {
        refetch().then((data) => {
          updateRoomsFromRefetch(data.data);
        });
        lastRefetchTime.current = Date.now();
      }
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(intervalId);
  }, [refetch, updateRoomsFromRefetch]);

  // call it once on load
  useEffect(() => {
    refetch();
  }, [refetch]);

  const handleAccept = useCallback(
    (roomID: string) => {
      navigate(`/room/${roomID}`);
    },
    [navigate]
  );

  const handleDecline = useCallback(
    async (roomID: string) => {
      const filteredRooms = invitedRooms.filter(
        (room) => room.roomID !== roomID
      );
      setInvitedRooms(filteredRooms);
      await declineRoomInvite({ variables: { roomID } });
    },
    [declineRoomInvite, invitedRooms]
  );

  return (
    <div
      className="w-full"
      style={{
        marginTop: 20,
        paddingBottom: 20,
        marginLeft: 64,
        height: "calc(100dvh - 77px)",
        maxHeight: "calc(100dvh - 77px)",
        overflowY: "auto",
      }}
    >
      {invitedRooms.length > 0 && (
        <div
          style={{
            position: "absolute",
            bottom: 20,
            right: 20,
            backgroundColor: "white",
            borderRadius: 10,
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            padding: 16,
            minWidth: 300,
            maxHeight: 400,
            overflowY: "auto",
          }}
        >
          <h3
            className="font-sans text-lg font-bold mb-4"
            style={{ marginBottom: 20 }}
          >
            Room Invitations
          </h3>
          {invitedRooms.map((room) => (
            <div
              key={room.roomID}
              className="mb-4 pb-4"
              style={{ marginBottom: 10 }}
            >
              <div className="flex justify-between mb-2">
                <div className="flex items-center" style={{ marginRight: 30 }}>
                  <img
                    key={`${room.roomID}-invite-img-${room.imageDownloadURL}`}
                    src={room.imageDownloadURL || EarthBackground}
                    alt={room.name}
                    className="w-10 h-10 mr-3"
                    style={{
                      borderRadius: 6,
                      marginRight: 10,
                    }}
                  />
                  <p className="font-sans font-semibold">{room.name}</p>
                </div>
                <div className="flex justify-end items-center">
                  <Button
                    size="sm"
                    color="success"
                    className="font-sans mr-2"
                    style={{
                      color: "white",
                    }}
                    onClick={() => handleAccept(room.roomID)}
                  >
                    Accept
                  </Button>
                  <Button
                    size="sm"
                    color="danger"
                    className="font-sans"
                    onClick={() => handleDecline(room.roomID)}
                  >
                    Decline
                  </Button>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      {/* Public rooms section */}
      <div className="flex flex-col items-start">
        <div>
          <p className="font-sans text-xl" style={{ fontWeight: "bold" }}>
            Shared rooms
          </p>
        </div>
        <div style={{ marginTop: 20 }} className="flex flex-wrap gap-4">
          {loading ? (
            <div style={{ height: 200 }} />
          ) : (
            sharedRooms.map((room: RoomMetadata) => (
              <CachedBackgroundImageDiv
                imageURL={room.imageDownloadURL || EarthBackground}
                key={room.roomID}
                roomID={room.roomID}
                draftID={
                  mostRecentlyEditedDraftData?.getMostRecentlyEditedDraft
                    .draftID
                }
              >
                <div
                  className="flex flex-col blurred-div"
                  style={{
                    margin: 10,
                    borderRadius: 5,
                    paddingLeft: 10,
                    paddingRight: 10,
                    paddingTop: 5,
                    paddingBottom: 5,
                    width: 180,
                  }}
                >
                  <p
                    className="font-sans text-white"
                    style={{
                      fontWeight: "bold",
                      fontSize: 16,
                      marginBottom: 4,
                    }}
                  >
                    {room.name}
                  </p>
                  <div className="flex flex-row justify-between">
                    <div className="flex flex-row items-center">
                      {room.private && (
                        <div style={{ marginRight: 5 }}>
                          <Lock color="white" variant="Bold" size={15} />
                        </div>
                      )}
                      {room.endTime && new Date(room.endTime) > currentTime ? (
                        <div className="flex flex-row items-center">
                          <div style={{ marginRight: 5 }}>
                            <Timer1 color="white" size={15} />
                          </div>
                          <p
                            className="font-sans"
                            style={{ fontSize: 12, color: "white" }}
                          >
                            {formatTimeDifference(
                              new Date(room.endTime),
                              currentTime
                            )}
                          </p>
                        </div>
                      ) : (
                        <div />
                      )}
                    </div>
                    <div className="flex flex-row items-center">
                      <div
                        className="flex flex-row items-center"
                        style={{ marginRight: 6 }}
                        onMouseEnter={() => setHoveredRoomID(room.roomID)}
                        onMouseLeave={() => setHoveredRoomID(null)}
                      >
                        {room.friendsInRoom.map((friend) => (
                          <motion.div
                            animate={{
                              marginLeft:
                                hoveredRoomID === room.roomID ? 2 : -8,
                            }}
                            transition={{
                              duration: 0.1,
                            }}
                            style={{
                              position: "relative",
                            }}
                            key={friend.userID}
                          >
                            <Avatar
                              src={friend.profilePhotoURL || ""}
                              style={{
                                width: 18,
                                height: 18,
                              }}
                              onMouseEnter={() =>
                                setHoveredFriendID(friend.userID)
                              }
                              onMouseLeave={() => setHoveredFriendID(null)}
                            />
                            {hoveredRoomID === room.roomID &&
                              hoveredFriendID === friend.userID && (
                                <div
                                  style={{
                                    position: "absolute",
                                    bottom: -40,
                                    left: -10,
                                    backgroundColor: "black",
                                    padding: 5,
                                    margin: 5,
                                  }}
                                >
                                  <p
                                    className="font-sans"
                                    style={{ color: "white", fontSize: 12 }}
                                  >
                                    {friend.username}
                                  </p>
                                </div>
                              )}
                          </motion.div>
                        ))}
                      </div>
                      <div style={{ marginRight: 2 }}>
                        <p
                          className="font-sans"
                          style={{ color: "white", fontSize: 12 }}
                        >
                          {room.numUsersInRoom}
                        </p>
                      </div>
                      <User color="white" size={15} />
                    </div>
                  </div>
                </div>
              </CachedBackgroundImageDiv>
            ))
          )}
        </div>
      </div>

      {/* Owned rooms section */}
      <div className="flex flex-col items-start" style={{ marginTop: 50 }}>
        <div>
          <p className="font-sans text-xl" style={{ fontWeight: "bold" }}>
            Your rooms
          </p>
        </div>
        <div style={{ marginTop: 20 }} className="flex flex-wrap gap-4">
          {loading
            ? null
            : ownedRooms.map((room: RoomMetadata) => (
                <CachedBackgroundImageDiv
                  key={room.roomID}
                  imageURL={room.imageDownloadURL || EarthBackground}
                  roomID={room.roomID}
                  draftID={
                    mostRecentlyEditedDraftData?.getMostRecentlyEditedDraft
                      .draftID
                  }
                >
                  {/* Room details */}
                  <div
                    className="flex flex-col blurred-div"
                    style={{
                      margin: 10,
                      borderRadius: 5,
                      paddingLeft: 10,
                      paddingRight: 10,
                      paddingTop: 5,
                      paddingBottom: 5,
                      width: 180,
                    }}
                  >
                    <p
                      className="font-sans text-white"
                      style={{
                        fontWeight: "bold",
                        fontSize: 16,
                        marginBottom: 4,
                      }}
                    >
                      {room.name}
                    </p>
                    <div className="flex flex-row justify-between">
                      <div className="flex flex-row items-center">
                        {room.private && (
                          <div style={{ marginRight: 5 }}>
                            <Lock color="white" size={15} variant="Bold" />
                          </div>
                        )}
                        {room.endTime &&
                        new Date(room.endTime) > currentTime ? (
                          <div className="flex flex-row items-center">
                            <div style={{ marginRight: 5 }}>
                              <Timer1 color="white" size={15} />
                            </div>
                            <p
                              className="font-sans"
                              style={{ fontSize: 12, color: "white" }}
                            >
                              {formatTimeDifference(
                                new Date(room.endTime),
                                currentTime
                              )}
                            </p>
                          </div>
                        ) : (
                          <div />
                        )}
                      </div>
                      <div className="flex flex-row items-center">
                        <div style={{ marginRight: 2 }}>
                          <p
                            className="font-sans"
                            style={{ color: "white", fontSize: 12 }}
                          >
                            {room.numUsersInRoom}
                          </p>
                        </div>
                        <User color="white" size={15} />
                      </div>
                    </div>
                  </div>
                </CachedBackgroundImageDiv>
              ))}

          {/* Create new room button */}
          <div
            onMouseEnter={() => setCreateNewHovered(true)}
            onMouseLeave={() => setCreateNewHovered(false)}
            className="flex flex-col items-center justify-center"
            style={{
              width: 200,
              height: 200,
              backgroundColor: createNewHovered ? "#7194A8" : "#e0e0e0",
              borderRadius: 10,
              cursor: "pointer",
            }}
            onClick={() => {
              navigate("/create-room");
            }}
          >
            <AddSquare color={"white"} size={80} style={{ strokeWidth: 0.5 }} />
            <p
              className="font-sans"
              style={{
                color: "white",
                fontSize: 12,
              }}
            >
              Create new room
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
