import { ActivityLog } from "../gql/graphql";

export function ActivityLogFeedItem({
  activityLog,
  draftID,
}: {
  activityLog: ActivityLog;
  draftID?: string;
}) {
  return (
    <div className="font-sans" style={{ fontSize: 14, marginBottom: 8 }}>
      {activityLog.templatedLog
        .split(/(\[(?:[^\[\]]|\\\[|\\\])*\]\((?:[^()]|\\\(|\\\))*\))/)
        .map((part, index) => {
          const match = part.match(
            /\[((?:[^\[\]]|\\\[|\\\])*)\]\(((?:[^()]|\\\(|\\\))*)\)/
          );
          if (match) {
            const [, text, url] = match;
            return (
              <a
                key={`activity-log-${activityLog.activityLogID}`}
                href={
                  url.replace(/\\(.)/g, "$1") +
                  `${draftID ? `/draft/${draftID}` : ""}`
                }
                className="hover:underline"
                style={{ fontWeight: "bold", color: "#7194a8" }}
              >
                {text.replace(/\\(.)/g, "$1")}
              </a>
            );
          }
          return part.replace(/\\(.)/g, "$1");
        })}
    </div>
  );
}
