import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Button, Textarea, Tooltip } from "@nextui-org/react";
import { CloseCircle } from "iconsax-react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Socket } from "socket.io-client";
import { useDebouncedCallback } from "use-debounce";

const GET_ROOM_DATA = gql`
  query getRoomDataPromptEditor($roomID: String!) {
    getRoomData(request: { roomID: $roomID }) {
      prompt
      promptHidden
    }
  }
`;

const UPDATE_ROOM_PROMPT_HIDDEN = gql`
  mutation updateRoomPromptHidden($request: UpdateRoomPromptHiddenRequest!) {
    updateRoomPromptHidden(request: $request) {
      success
    }
  }
`;

const UPDATE_ROOM_PROMPT = gql`
  mutation updateRoomPrompt($request: UpdateRoomPromptRequest!) {
    updateRoomPrompt(request: $request) {
      success
    }
  }
`;
const GENERATE_PROMPT = gql`
  query GeneratePrompt {
    generatePrompt {
      prompt
    }
  }
`;

export default function PromptEditor({ socket }: { socket: Socket | null }) {
  const { roomID } = useParams();
  const { data, loading, called } = useQuery(GET_ROOM_DATA, {
    variables: { roomID },
    onCompleted: (data) => {
      setPrompt(data.getRoomData.prompt);
      setPromptHidden(data.getRoomData.promptHidden);
    },
  });
  const [updateRoomPrompt] = useMutation(UPDATE_ROOM_PROMPT);
  const [updateRoomPromptHidden] = useMutation(UPDATE_ROOM_PROMPT_HIDDEN);
  const [generatePrompt] = useLazyQuery(GENERATE_PROMPT, {
    fetchPolicy: "no-cache",
  });
  const debouncedUpdateRoomPrompt = useDebouncedCallback(updateRoomPrompt, 300);
  const [prompt, setPrompt] = useState("");
  const [promptHidden, setPromptHidden] = useState(false);
  const [closePromptButtonHover, setClosePromptButtonHover] = useState(false);
  useEffect(() => {
    if (socket && !socket.hasListeners("prompt_update")) {
      socket.on("prompt_update", (...args) => {
        setPrompt(args[0].message);
      });
    }
  }, [socket]);

  if (promptHidden || loading || !called) {
    return null;
  }

  return (
    <div
      className="font-sans"
      style={{ padding: 24, borderBottom: "1px solid #e0e0e0" }}
    >
      <div
        className="flex flex-row items-start justify-between"
        style={{ paddingBottom: 16 }}
      >
        <p style={{ fontSize: 14 }}>Room prompt</p>
        <div className="flex flex-row items-center gap-2">
          <Button
            size="sm"
            style={{ height: 24, fontSize: 14 }}
            onClick={async () => {
              const { data } = await generatePrompt();
              if (data) {
                setPrompt(data.generatePrompt.prompt);
                updateRoomPrompt({
                  variables: {
                    request: { roomID, prompt: data.generatePrompt.prompt },
                  },
                });
                socket?.emit("prompt_update", {
                  prompt: data.generatePrompt.prompt,
                  roomID,
                });
              }
            }}
          >
            <p style={{ fontSize: 14 }}>Generate</p>
          </Button>
          <div style={{ position: "relative" }}>
            <Button
              size="sm"
              variant="light"
              style={{ height: 24, fontSize: 14 }}
              onClick={async () => {
                await updateRoomPromptHidden({
                  variables: {
                    request: { roomID, promptHidden: !promptHidden },
                  },
                });
                setPromptHidden(!promptHidden);
              }}
              isIconOnly
              isDisabled={promptHidden}
              onMouseEnter={() => setClosePromptButtonHover(true)}
              onMouseLeave={() => setClosePromptButtonHover(false)}
            >
              x
            </Button>
            {closePromptButtonHover && (
              <div
                className="font-sans"
                style={{
                  position: "absolute",
                  zIndex: 10000,
                  backgroundColor: "black",
                  color: "white",
                  width: 80,
                  left: -25,
                  top: -25,
                  padding: 4,
                  fontSize: 12,
                }}
              >
                Hide prompt
              </div>
            )}
          </div>
        </div>
      </div>
      <Textarea
        value={prompt}
        onChange={(e) => {
          setPrompt(e.target.value);
          debouncedUpdateRoomPrompt({
            variables: { request: { roomID, prompt: e.target.value } },
          });
          socket?.emit("prompt_update", {
            prompt: e.target.value,
            roomID,
          });
        }}
      />
    </div>
  );
}
