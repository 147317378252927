import { Avatar, Button, Input } from "@nextui-org/react";
import dayjs from "dayjs";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Socket } from "socket.io-client";
import { useInterval } from "../hooks/useInterval";
import { gql, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { ArrowUp, ArrowUp2 } from "iconsax-react";
import { Message } from "../gql/graphql";
import { useUserInfo } from "../hooks/useUserInfo";
import { getUnauthedUserHash } from "../utils/unauthedLocalStorage";
import { useMediaQuery } from "react-responsive";

type RoomState = {
  usersInRoom: { username: string; profilePhotoDownloadURL: string | null }[];
};

const COLOR_DURATION = 200;

const GET_ROOM_DATA = gql`
  query GetRoomDataRoomBox($roomID: String!) {
    getRoomData(request: { roomID: $roomID }) {
      wordsWritten
    }
  }
`;

const GET_MESSAGES_FOR_ROOM = gql`
  query GetMessagesForRoomFooter($roomID: String!) {
    getMessagesForRoom(request: { roomID: $roomID }) {
      content
      messageID
      createdAt
      username
    }
  }
`;

export default function DocumentFooter({
  liveWordCount,
  socket,
}: {
  socket: Socket | null;
  liveWordCount: number;
}) {
  const isMobile = useMediaQuery({
    query: "(max-width: 680px)",
  });
  const { roomID } = useParams();
  const [messages, setMessages] = useState<Message[]>([]);
  const { userID, randomUsername, settled } = useUserInfo();
  const [encryptedTooltipOpen, setEncryptedTooltipOpen] = useState(false);
  const [timeOfDayTooltipOpen, setTimeOfDayTooltipOpen] = useState(false);
  const [hoveredUsername, setHoveredUsername] = useState<string | null>(null);
  const [chatBarHovered, setChatBarHovered] = useState(false);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [newChatMessage, setNewChatMessage] = useState(false);
  const [roomState, setRoomState] = useState<RoomState>({
    usersInRoom: [],
  });
  const [wordsAddedUsers, setWordsAddedUsers] = useState<{
    [userID: string]: Date;
  }>({});
  const [wordsDeletedUsers, setWordsDeletedUsers] = useState<{
    [userID: string]: Date;
  }>({});
  const [wordsWritten, setWordsWritten] = useState(0);

  // initial fetch of messages
  useQuery(GET_MESSAGES_FOR_ROOM, {
    variables: { roomID },
    onCompleted: (data) => {
      setMessages(data.getMessagesForRoom);
    },
  });
  const scrollViewRef = useRef<HTMLDivElement>(null);
  const [messageInput, setMessageInput] = useState("");
  const scrollWindowRef = useRef<HTMLDivElement>(null);
  const sendMessage = useCallback(() => {
    if (socket) {
      socket.emit("send_chat_message", {
        message: messageInput,
        roomID,
        userID,
      });
      setMessageInput("");
    }
  }, [messageInput, roomID, socket, userID]);

  useQuery(GET_ROOM_DATA, {
    variables: { roomID },
    onCompleted: (data) => setWordsWritten(data.getRoomData.wordsWritten),
  });
  const currentHour = dayjs().hour();
  const timeOfDayString = useMemo(() => {
    if (currentHour < 1) {
      return "nightfall 🥱";
    } else if (currentHour < 4) {
      return "dead of night ☠️";
    } else if (currentHour < 7) {
      return "daybreak 🐓";
    } else if (currentHour < 11) {
      return "morning ☕";
    } else if (currentHour < 13) {
      return "midday 🕛";
    } else if (currentHour < 17) {
      return "afternoon ☀️";
    } else if (currentHour < 22) {
      return "evening 🌙";
    } else {
      return "nightfall 🥱";
    }
  }, [currentHour]);

  const [currentDate, setCurrentDate] = useState(new Date());

  useInterval(() => {
    setCurrentDate(new Date());
  }, 200);

  const computeBorderColor = useCallback(
    (username: string) => {
      if (
        wordsAddedUsers[username] &&
        currentDate.getTime() - wordsAddedUsers[username].getTime() <
          COLOR_DURATION
      ) {
        // return green with opacity depending on time elapsed
        // should fade from green to transparent over 2 seconds
        return "#53bf79";
        // return greenColorMap(
        //   (Date.now() - wordsAddedUsers[username].getTime()) / 2000
        // );
      } else if (
        wordsDeletedUsers[username] &&
        currentDate.getTime() - wordsDeletedUsers[username].getTime() <
          COLOR_DURATION
      ) {
        return "#ff6b6b";
        // return redColorMap(
        //   (Date.now() - wordsDeletedUsers[username].getTime()) / 2000
        // );
      } else {
        return null;
      }
    },
    [currentDate, wordsAddedUsers, wordsDeletedUsers]
  );

  const [joinedRoom, setJoinedRoom] = useState(false);

  useEffect(() => {
    if (socket) {
      if (!socket.hasListeners("room_update")) {
        socket.on("room_update", (...args) => {
          if (args[0].type === "room_state") {
            const newUsersInRoom = [];
            const usernamesAlreadyInRoom = new Set();
            for (const user of args[0].usersInRoom) {
              if (!usernamesAlreadyInRoom.has(user.username)) {
                newUsersInRoom.push(user);
                usernamesAlreadyInRoom.add(user.username);
              }
            }
            setRoomState({ ...roomState, usersInRoom: newUsersInRoom });
          } else if (args[0].type === "user_left") {
            setRoomState({
              ...roomState,
              usersInRoom: roomState.usersInRoom.filter(
                (user) => user.username !== args[0].username
              ),
            });
          } else if (args[0].type === "awareness_activity") {
            if (args[0].content === "word_added") {
              setWordsAddedUsers({
                ...wordsAddedUsers,
                [args[0].username]: new Date(),
              });
            } else if (args[0].content === "word_deleted") {
              setWordsDeletedUsers({
                ...wordsDeletedUsers,
                [args[0].username]: new Date(),
              });
            }
          } else if (args[0].type === "words_written") {
            setWordsWritten(args[0].wordsWritten);
          }
        });
      }
    }
    return () => {
      if (socket) {
        socket.off("room_update");
      }
    };
  }, [
    roomState,
    roomState.usersInRoom,
    socket,
    wordsAddedUsers,
    wordsDeletedUsers,
  ]);

  useEffect(() => {
    if (socket) {
      socket.on("disconnect", () => {
        setJoinedRoom(false);
      });
      if (!joinedRoom && settled) {
        if (userID) {
          socket.emit("user_room_join", { roomID, userID });
          setJoinedRoom(true);
        } else {
          const unauthedUserHash = getUnauthedUserHash();
          socket.emit("user_room_join", {
            roomID,
            userID: `guest-${unauthedUserHash}`,
            username: randomUsername,
          });
          setJoinedRoom(true);
        }
      }
    }
  }, [joinedRoom, randomUsername, roomID, socket, userID, settled]);

  const scrollToBottom = useCallback(() => {
    if (scrollWindowRef.current) {
      scrollWindowRef.current.scrollTop = scrollWindowRef.current.scrollHeight;
    }
  }, []);

  useEffect(() => {
    if (socket) {
      if (!socket.hasListeners("new_message")) {
        socket.on("new_message", (...args) => {
          setMessages((prevMessages) => [...prevMessages, args[0]]);
          if (isChatOpen) {
            setTimeout(scrollToBottom, 10);
          } else {
            setNewChatMessage(true);
          }
        });
      }
    }
    return () => {
      if (socket) {
        socket.off("new_message");
      }
    };
  }, [socket, scrollToBottom, isChatOpen]);

  useEffect(() => {
    if (isChatOpen) {
      scrollToBottom();
    }
  }, [isChatOpen, scrollToBottom]);
  return (
    <div
      className="flex flex-col absolute items-end"
      style={{ bottom: 0, right: 20 }}
    >
      <div>
        <div>
          <div
            className="flex flex-row items-center justify-end"
            onMouseEnter={() => {
              setChatBarHovered(true);
            }}
            onMouseLeave={() => {
              setChatBarHovered(false);
            }}
            onClick={() => {
              setIsChatOpen(!isChatOpen);
              setNewChatMessage(false);
            }}
            style={{
              backgroundColor: chatBarHovered ? "#f7fafc" : "white",
              padding: 4,
              width: 300,
              maxWidth: 300,
              cursor: "pointer",
            }}
          >
            {newChatMessage && (
              <div
                style={{
                  backgroundColor: "#456577",
                  width: 5,
                  height: 5,
                  borderRadius: 5,
                  marginRight: 8,
                }}
              />
            )}
            <div style={{ marginRight: 4 }}>
              <p className="font-sans" style={{ fontSize: 12 }}>
                Writing with:
              </p>
            </div>
            {roomState.usersInRoom.map(
              ({ profilePhotoDownloadURL, username }) => (
                <div
                  className="flex flex-col items-center relative"
                  key={`room_user_${username}`}
                  style={{ marginRight: 4 }}
                >
                  <Avatar
                    src={profilePhotoDownloadURL ?? ""}
                    showFallback
                    isBordered={!!computeBorderColor(username)}
                    style={{
                      transitionProperty: "outline-color",
                      transitionDuration: "0.2s",
                      outlineColor: computeBorderColor(username) ?? undefined,
                      height: 20,
                      width: 20,
                    }}
                    onMouseEnter={() => {
                      setHoveredUsername(username);
                    }}
                    onMouseLeave={() => {
                      setHoveredUsername(null);
                    }}
                  />
                  {hoveredUsername === username && (
                    <div
                      className="absolute"
                      style={{ backgroundColor: "white", right: 0, bottom: 20 }}
                    >
                      <p style={{ fontSize: 12, color: "gray" }}>{username}</p>
                    </div>
                  )}
                </div>
              )
            )}
            <div className="flex items-center ml-2">
              <ArrowUp2
                size={16}
                color="#718096"
                className={`transition-transform duration-300 ${
                  isChatOpen ? "rotate-180" : ""
                }`}
              />
            </div>
          </div>
          <div
            className={`flex-1 bg-white flex flex-col ${
              isChatOpen ? "border border-gray-400" : ""
            } transition-all duration-300 ease-in-out ${
              isChatOpen ? "w-[280px]" : "w-0"
            }`}
            style={{
              marginBottom: 0,
              marginTop: 0,
              marginLeft: isChatOpen ? 0 : 20,
              display: isChatOpen ? "block" : "none",
              maxWidth: 300,
              width: 300,
            }}
          >
            <div
              className="flex-1"
              ref={scrollWindowRef}
              style={{
                maxHeight: 300,
                minHeight: 50,
                overflowY: "auto",
              }}
            >
              <div
                className="flex-1 flex flex-col justify-end"
                style={{ padding: 20 }}
              >
                {messages.map((message) => (
                  <div
                    key={`message_${message.messageID}`}
                    style={{ marginBottom: 10 }}
                  >
                    <div className="font-sans flex flex-row justify-between items-center">
                      <p style={{ fontSize: 12, fontWeight: "bold" }}>
                        {message.username}
                      </p>
                      <p
                        className="font-mono"
                        style={{
                          fontSize: 12,
                          textAlign: "right",
                        }}
                      >
                        {new Date(message.createdAt).toLocaleTimeString([], {
                          hour: "2-digit",
                          minute: "2-digit",
                        })}
                      </p>
                    </div>
                    <div
                      className="flex flex-col"
                      style={{
                        width: "100%",
                      }}
                    >
                      <p
                        className="font-sans"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {message.content}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
              <div ref={scrollViewRef} />
            </div>
            <div
              className="flex"
              style={{ paddingLeft: 16, paddingRight: 16, paddingBottom: 16 }}
            >
              <Input
                placeholder="Chat here"
                classNames={{
                  inputWrapper: ["h-[24px] font-sans"],
                }}
                variant="underlined"
                size="sm"
                onValueChange={(value) => {
                  setMessageInput(value);
                }}
                value={messageInput}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && messageInput.trim()) {
                    sendMessage();
                  }
                }}
              />

              <div style={{ marginLeft: 16 }}>
                <Button
                  size="sm"
                  className="font-sans"
                  onClick={sendMessage}
                  isDisabled={!messageInput.trim()}
                >
                  Send
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="flex flex-row items-center"
        style={{
          paddingBottom: 4,
          paddingTop: 4,
          paddingLeft: 4,
          backgroundColor: "white",
          cursor: "default",
        }}
      >
        {timeOfDayTooltipOpen && (
          <div
            className="absolute"
            style={{
              bottom: 30,
              right: 0,
              backgroundColor: "black",
              padding: 4,
              width: 316,
              borderRadius: 8,
            }}
          >
            <p
              className="font-sans"
              style={{ fontSize: 12, color: "white", textAlign: "center" }}
            >
              The time of day based on your timezone. You can check your profile
              to see your writing habits over time.
            </p>
          </div>
        )}
        {encryptedTooltipOpen && (
          <div
            className="absolute"
            style={{
              bottom: 30,
              backgroundColor: "black",
              padding: 4,
              width: 316,
              right: 0,
              borderRadius: 8,
            }}
          >
            <p
              className="font-sans"
              style={{ fontSize: 12, color: "white", textAlign: "center" }}
            >
              Your document, notes, and title are completely private. No one
              besides you, not even the Draft Zero team, can read it.
            </p>
          </div>
        )}
        <p className="font-sans" style={{ fontSize: 12, color: "gray" }}>
          {wordsWritten} word{wordsWritten !== 1 ? "s" : ""} in room
        </p>
        <div style={{ marginLeft: 4, marginRight: 4 }}>
          <p>·</p>
        </div>
        <p className="font-sans" style={{ fontSize: 12, color: "gray" }}>
          {liveWordCount} word{liveWordCount !== 1 ? "s" : ""}
        </p>
        <div style={{ marginLeft: 4, marginRight: 4 }}>
          <p>·</p>
        </div>
        {!isMobile && [
          <div
            onMouseEnter={() => {
              setTimeOfDayTooltipOpen(true);
            }}
            onMouseLeave={() => {
              setTimeOfDayTooltipOpen(false);
            }}
          >
            <p
              className="font-sans"
              style={{ fontSize: 12, color: "gray", cursor: "help" }}
            >
              {timeOfDayString}
            </p>
          </div>,
          <div style={{ marginLeft: 4, marginRight: 4 }}>
            <p>·</p>
          </div>,
        ]}
        <p
          className="font-sans"
          style={{ fontSize: 12, color: "gray", cursor: "help" }}
          onMouseEnter={() => {
            setEncryptedTooltipOpen(true);
          }}
          onMouseLeave={() => {
            setEncryptedTooltipOpen(false);
          }}
        >
          encrypted
        </p>
      </div>
    </div>
  );
}
